<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Google Sheets - {{this.form.type}} new entity
			el-form(:form="form")
				h4.text-left
					b Spreadsheet Basic Data
				el-row.mt-3(:gutter="12")
					el-col(:xs="24")
				el-row(:gutter="12")

					el-col(:xs="24", :sm="8" :md="6")
						el-form-item(label="Hash" v-model="form.hash" :error="errors.hash.s ? errors.hash.m : ''" v-bind:class="{'is-invalid': errors.hash.s}")
							el-input(v-model="form.hash" placeholder="Hash" )

					el-col(:xs="24", :sm="8" :md="6")
						el-form-item(label="Title" v-model="form.title" :error="errors.title.s ? errors.title.m : ''" v-bind:class="{'is-invalid': errors.hash.s}")
							el-input(v-model="form.title" placeholder="Title" )

					el-col(:xs="24", :sm="6")
						el-form-item(label="Brand" :error="errors.brand_id.s ? errors.brand_id.m : ''" v-bind:class="{'is-invalid': errors.brand_id.s}")
							el-select.w-100(filterable="", v-model="form.brand_id")
								el-option(value="" label="- Select Brand -"  :disabled="true")
								el-option(v-for="o of brandOptions" :value="o.v" :label="o.t")

					el-col(:xs="24", :sm="6")
						el-form-item(label="Integrations" :error="errors.integration_id.s ? errors.integration_id.m : ''" v-bind:class="{'is-invalid': errors.integration_id.s}")
							el-select.w-100(filterable="", v-model="form.integration_id"  )
								el-option(value="" label="- Select Integration -" :disabled="true")
								el-option(v-for="o of integrationOptions" v-if="form.brand_id === o.b" :value="o.v" :label="o.bn + ' - ' + o.t + (o.n ? ` (${o.n})` : '')")
					el-col(:xs="24", :sm="4")
						el-form-item(label="In Use" label-position="top")
							el-radio-group.w-100(v-model="form.in_use")
								el-radio(:label="true") On
								el-radio(:label="false") Off


				el-divider
				h4.text-left
					.d-flex.justify-content-between
						b JustClick
				el-row(:gutter="12")
					el-col(:xs="24", :sm="8" :md="6")
						el-form-item(label="Success Group" v-model="form.hash" :error="errors.jc_success.s ? errors.jc_success.m : ''" v-bind:class="{'is-invalid': errors.jc_success.s}")
							el-input(v-model="form.jc_success" placeholder="JustClick Success Group" )

					el-col(:xs="24", :sm="8" :md="6")
						el-form-item(label="Failed Group" v-model="form.hash" :error="errors.jc_failed.s ? errors.jc_failed.m : ''" v-bind:class="{'is-invalid': errors.jc_failed.s}")
							el-input(v-model="form.jc_failed" placeholder="JustClick Failed Group" )
				el-divider

				h4.text-left
					b Telegram
				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Telegram Notification Channel" label-position="top")
							.w-100.text-left.d-block
								el-tag(:key='tag', v-for='tag in form.tg_channel_id', closable='', :disable-transitions='false', @close='handleClose(tag)')
									| {{tag}}
								el-input.input-new-tag(v-if='inputVisible', v-model='inputValue', ref='saveTagInput', size='mini', @keyup.enter.native='handleInputConfirm', @blur='handleInputConfirm')
								el-button.button-new-tag(v-else='', size='small', @click='showInput') + New Tag

				el-divider
				h4.text-left
					b Override Parameters
				p.text-left If set, these parameters will override the parameters that were set in "integration" at "misc" section for this funnel.

				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Override Parameters")
							el-button(type="info" icon="el-icon-plus" @click="addRow('override_params')") Add
						el-row.mt-1.mb-1(v-for="(o, i) of form.override_params" :gutter="6")
							el-col(:span="8")
								el-input(v-model="o.key" placeholder="Key")
							el-col(:span="15")
								el-input(v-model="o.value" placeholder="Value")
							el-col(:span="1")
								el-button(icon="el-icon-minus" type="danger" @click="() => form.override_params.splice(i,1)")


				el-divider
				h4.text-left
					b URI Query Params To Payload Fields Mapping
				p.text-left The following params would be caught from the URI. For example - the landing page was loaded with&nbsp;
					span.text-danger clickId=qqqwwweee&nbsp;
					| , but, you want to send the broker to a field "custom1" - You must choose the key and map to the foreign field.

				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Fields Mapping")
							el-button(type="info" icon="el-icon-plus" @click="addRow('fields_mapping')") Add
						el-row.mt-1.mb-1(v-for="(o, i) of form.fields_mapping" :gutter="6")
							el-col(:span="8")
								el-input(v-model="o.key" placeholder="Query Param")
							el-col(:span="15")
								el-input(v-model="o.value" placeholder="Foreign Field")
							el-col(:span="1")
								el-button(icon="el-icon-minus" type="danger" @click="() => form.fields_mapping.splice(i,1)")


				el-divider
				//pre {{form.worksheet}}
				h4.text-left
					.d-flex.justify-content-between
						b Worksheets
						el-button(v-b-tooltip.html.hover.right="", title="Add New Worksheet", type="success", size="small",  icon="el-icon-plus", @click="addWorksheet('.ws-card:last-child')")
				el-row(:gutter="12")
					el-col(:xs="24")
						el-card.m-1.ws-card(v-for="(w,i) of form.worksheet", shadow="hover" )
							.clearfix.d-flex.justify-content-start(slot="header")
								.d-flex.justify-content-between.w-100
									h5
										b(v-if="w.id") Sheet: {{'#' + w.external_id}}
										el-badge(value="new" v-else="")
											b Sheet: {{'#' + w.external_id}}
									el-button(v-b-tooltip.html.hover.right="", title="Remove Worksheet", type="danger", size="small",  icon="el-icon-remove", @click="removeWorksheet(i)")

							el-row(:gutter="12")
								el-col(:xs="12" :sm="8" :md="4")
									el-form-item(label="External ID" :error="errors.worksheet[i].external_id.s ? errors.worksheet[i].external_id.m : ''" v-bind:class="{'is-invalid': errors.worksheet[i].external_id.s}")
										el-input(v-model="w.external_id")

								el-col(:xs="12", :sm="8" :md="4")
									el-form-item(label="Language" :error="errors.worksheet[i].language.s ? errors.worksheet[i].language.m : ''" v-bind:class="{'is-invalid': errors.worksheet[i].language.s}")
										el-select.w-100(filterable="", v-model="w.language")
											el-option(value="" label="- Select language -"  :disabled="true")
											el-option(v-for="o of langOptions" :value="o.v" :label="o.t")



								el-col(:xs="24", :sm="8" :md="4")
									el-form-item(label="Accepts E-Mails" label-position="top")
										el-select.w-100(v-model="w.excepts_mails")
											el-option(label="OVERRIDE" value="OVERRIDE")
											el-option(label="EMPTY_ONLY" value="EMPTY_ONLY")
											el-option(label="NORMAL" value="NORMAL")

								el-col(:xs="24", :sm="8" :md="4")
									el-form-item(label="Custom Fix" label-position="top")
										el-select.w-100(v-model="w.custom_fix_id")
											el-option(:value="null" label="- Select Custom Fix -")
											el-option(v-for="o of customFixOptions" :label="o.t" :value="o.v")

								el-col(:xs="24", :sm="4")
									el-form-item(label="Guess Country By Phone" label-position="top")
										el-radio-group.w-100(v-model="w.country_by_phone_guess")
											el-radio(:label="true") By Phone
											el-radio(:label="false") Column Priority

								el-col(:xs="24", :sm="4")
									el-form-item(label="In Use" label-position="top")
										el-radio-group.w-100(v-model="w.in_use")
											el-radio(:label="true") On
											el-radio(:label="false") Off

								el-col(:span="24")
									el-form-item(label="Keys" label-position="top" :error="errors.worksheet[i].keys.s ? errors.worksheet[i].keys.m : ''" v-bind:class="{'is-invalid': errors.worksheet[i].keys.s}")
										b-form-tags(
											input="", :id="`worksheet-tag-${i}`",
											v-model="w.keys"
											placeholder=" Enter new keys separated by space"
											remove-on-delete
											:input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
											separator=",;"
										)
								el-col(:span="24" )
									el-form-item(label="Link To Worksheet:")
										span.chooseable.text-left(@click="openLink(`${link}${form.hash}/edit#gid=${w.external_id}`)")  {{link + form.hash + '/edit#gid=' + w.external_id}}
				.pt-2
				el-divider
				el-row(:gutter="12" justify="between")
					el-button(@click="submit()" type="primary" v-b-tooltip.html.hover-right="", title="Save and redirect back") Save
					el-button(type="danger" @click="$router.push({name: 'iframe-list'})" v-b-tooltip.html.hover-right="", title="Go Back") Cancel
</template>


<style lang="scss">
.input-new-tag {
	width: 120px;
	margin-left: 10px;
	vertical-align: bottom;
}

.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

.el-tag + .el-tag {
	margin-left: 10px;
}
</style>


<script lang="js">
import { worksheet, worksheetErrors, spreadSheetErrors } from '../../../struct';
import VueScrollTo from 'vue-scrollto';
import Vue from "vue";

let clearBeforeSend = (data) => {
	let body = JSON.parse(JSON.stringify(data));
	console.log('...', body, ',,,')
	delete body.type;
	body.worksheet = body.worksheet.map(w => {
		delete w.spreadsheet_id;
		delete w.language_full;
		return w;
	});
	let hash = {};
	body.override_params.forEach(e => hash[e.key] = e.value);
	body.override_params = {...hash};
	hash = {};
	body.fields_mapping.forEach(e => hash[e.key] = e.value);
	body.fields_mapping = hash;
	return body;
};


export default {
	name: 'iframe-edit-spreadsheet',
	async beforeRouteEnter(to, from, next) {
		if (to.name === 'iframe-add-spreadsheet') {
			try {
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '3673e785-6d24-40ae-a91b-d7dac9e4c50b,9e832481-2c11-4021-ba13-0dcc5487e3f7,49f06cec-46b0-4237-bf9a-d4d3969e5af6,1e0d4745-6245-4b03-9dd7-e97cbabd6a96'})
				next(vm => {
					vm.integrationOptions = options.integration_extra;
					vm.brandOptions = options.brand;
					vm.langOptions = options.language;
					vm.customFixOptions = options.custom_fix;
					vm.errors = {...spreadSheetErrors};
					vm.errors.worksheet = [];
				});
			} catch (e) {
				console.error(e);
				Vue.router.push({name: 'brand-list'})
			}
		} else if (to.name === 'iframe-edit-spreadsheet') {
			try {
				let result = await Vue.apix.sendHttpRequest('GET', 'spreadsheet/get', {id: to.params.id});
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '3673e785-6d24-40ae-a91b-d7dac9e4c50b,9e832481-2c11-4021-ba13-0dcc5487e3f7,49f06cec-46b0-4237-bf9a-d4d3969e5af6,1e0d4745-6245-4b03-9dd7-e97cbabd6a96'})
				next(vm => {
					vm.entity_id = to.params.id;
					vm.integrationOptions = options.integration_extra;
					vm.brandOptions = options.brand;
					vm.langOptions = options.language;
					vm.customFixOptions = options.custom_fix;
					vm.form = {
						type: 					'edit',
						title: 					result.title,
						brand_id: 			result.brand_id,
						integration_id: result.integration_id,
						in_use: 				result.in_use,
						// custom_fix_id: 	result.custom_fix_id,
						// excepts_mails: 	result.excepts_mails,
						hash: 					result.hash,
						jc_success: 		result.jc_success,
						jc_failed: 			result.jc_failed,
						worksheet: 			result.worksheet,
						tg_channel_id: 	result.tg_channel_id,
						override_params: [],
						fields_mapping: []
					};

					Object.entries(result.override_params).forEach(e => {
						vm.counter.override_params++;
						vm.form.override_params.push({key: e[0], value: e[1]})
					});
					Object.entries(result.fields_mapping).forEach(e => {
						vm.counter.fields_mapping++;
						vm.form.fields_mapping.push({key: e[0], value: e[1]})
					});
					vm.errors = {...spreadSheetErrors};
					vm.errors.worksheet = [...result.worksheet.map(e => Object.assign({}, worksheetErrors))];
				});
			} catch (e) {
				console.error(e);
				Vue.router.push({name: 'brand-list'})
			}
		}
	},
	data() {
		return {
			counter: {
				override_params: 0,
				fields_mapping: 0,
			},
      inputVisible: false,
      inputValue: '',
			form: {
				type: 'add',
				hash: '',
				title: '',
				jc_success: '',
				jc_failed: '',
				in_use: false,
				// custom_fix_id: '',
				integration_id: '',
				brand_id: '',
				worksheet: [],
				tg_channel_id: [],
				override_params: [],
				fields_mapping: [],
			},
			errors: spreadSheetErrors,
			brandOptions: [],
			integrationOptions: [],
			langOptions: [],
			customFixOptions: [],
		}
	},
	computed: {
		link() {
			return process.env.VUE_APP_GOOGLE_SHEETS_URL || 'https://docs.google.com/spreadsheets/d/'
		}
	},
	methods: {
		addRow(type) {
			this.form[type].push({key: `key_${this.counter[type]}`, value: `value_${this.counter[type]}`});
			this.counter[type]++;
		},
		async submit() {
			if(!this.validate()) return this.$notify.error('Validation failed. Please, review the errors');
			let loader = this.$loading.show();
			try {
				if (this.form.type === 'add') {
					let result = await this.$apix.sendHttpRequest('POST','spreadsheet/create',clearBeforeSend(this.form));
					if (result.id) this.$router.push({name: 'iframe-list'})
					loader.hide()
				} else if (this.form.type === 'edit') {
					let result = await this.$apix.sendHttpRequest('PUT','spreadsheet/update/' + this.entity_id,clearBeforeSend(this.form));
					loader.hide();
					if(result) this.$router.push({name: 'iframe-list'});
					else this.$notify.error('Update failed')
				}
			} catch (e) {
				console.error(e);
			}
		},
		openLink(url) {
			window.open(url, "_blank")
		},
		validate() {
			this.errors = {...spreadSheetErrors, ...{worksheet: this.form.worksheet.map(e => Object.assign({}, worksheetErrors))}};

			let error = false;

			if(this.form.hash === '') {
				this.errors.hash = { s: true, m: 'Hash cannot be empty' };
				error = true;
			}

			if(this.form.title === '') {
				this.errors.title = { s: true, m: 'Title cannot be empty' };
				error = true;
			}

			if(this.form.brand_id === '') {
				this.errors.brand_id = { s: true, m: 'Select Brand' };
				error = true;
			}

			if(this.form.integration_id === '') {
				this.errors.integration_id = { s: true, m: 'Select Integration' };
				error = true;
			}

			this.form.worksheet.forEach((w,i) => {
				if(w.external_id === '') {
					this.errors.worksheet[i].external_id = { s: true, m: 'External ID cannot be empty' };
					error = true;
				}

				if(w.language === '') {
					this.errors.worksheet[i].language = { s: true, m: 'Language cannot be empty' };
					error = true;
				}

				if(w.keys.length === 0) {
					this.errors.worksheet[i].keys = { s: true, m: 'Keys cannot be empty' };
					error = true;
				} else if(!w.keys.includes('EMAIL')) {
					this.errors.worksheet[i].keys = { s: true, m: 'EMAIL is missing' };
					error = true;
				} else if(!w.keys.includes('NAME')) {
					this.errors.worksheet[i].keys = { s: true, m: 'NAME is missing' };
					error = true;
				} else if(!w.keys.includes('PHONE')) {
					this.errors.worksheet[i].keys = { s: true, m: 'PHONE is missing' };
					error = true;
				}
			});
			return !error;
		},
		addWorksheet(el) {
			this.form.worksheet = [...this.form.worksheet, Object.assign({}, worksheet)];
			this.errors.worksheet = [...this.errors.worksheet, Object.assign({}, worksheetErrors)];
			this.$scrollTo(el, 1000);
		},
		removeWorksheet(i) {
			this.form.worksheet.splice(i, 1)
			this.errors.worksheet.splice(i, 1)
		},
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleClose(tag) {
      this.form.tg_channel_id.splice(this.form.tg_channel_id.indexOf(tag), 1);
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if(!inputValue || inputValue === '') {
        this.inputVisible = false;
        this.inputValue = '';
      } else if (inputValue && inputValue.length > 10 && inputValue.length < 15) {
        this.form.tg_channel_id.push(inputValue);
        this.inputVisible = false;
        this.inputValue = '';
      } else {
        this.$notify.error({title: 'Validation Error', message: 'Validation failed. Telegram channel must consist of either 11 or 14 characters'})
      }
    },
	}
}
</script>
